body {
    font-family: "Roboto", "serif";
}

/* .Mui-focused {
    color: "#2F4D8B !important"
} */
.MuiFilledInput-input .MuiInputBase-input {
    background: "#F8F8F8 !important"
}

.Layout-popover {
    width: 248px !important;
}

.MuiCheckbox-root.Mui-checked {
    color: #2F4D8B
}
.MuiSwitch-switchBase.Mui-checked {
    color: "#2F4D8B",
    /* "&:hover": {
        backgroundColor: alpha(primaryBlue, theme.palette.action.hoverOpacity),
    } */
}

.MuiSwitch-switchBase.Mui-checked, .MuiSwitch-track {
    background-color: "#2F4D8B",
}

/* TODO: move to inline styles uing makeStyles */
/* dayreport */
/* .MuiDataGrid-columnHeader--sortable[data-field="workCenterName"]{
    padding-left: 52px !important;
}
.MuiDataGrid-cell--textLeft[data-field="workCenterName"]{
    padding-left: 28px !important;
} */

/* input type number remove arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
  }

/* .css-cs172o-MuiDataGrid-root .MuiDataGrid-columnHeader,
.css-cs172o-MuiDataGrid-root .MuiDataGrid-cell{
    padding: 0px 2px;
} */


.css-cs172o-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
    /* padding: 0 15px; */
    /* padding-right: 0; */
}


/* .MuiDataGrid-columnHeader--sortable[data-field="hours"]{
    padding-left: 24px;
}


.MuiDataGrid-columnHeader--sortable[data-field="firstName"],
.MuiDataGrid-columnHeader--sortable[data-field="lastName"]{
    padding-left: 42px !important;
}
.MuiDataGrid-cell--textLeft[data-field="firstName"],
.MuiDataGrid-cell--textLeft[data-field="lastName"]{
    padding-left: 44px !important;
} */

/* .MuiDataGrid-columnHeader--sortable[data-field="startDateTime"], */
/* .MuiDataGrid-columnHeader--sortable[data-field="endDateTime"]{ */
    /* border:1px solid red; */
    /* min-width:130px !important; NOTE:enable*/
    /* width:130px !important; NOTE;ENa;eb */
    /* padding-left:38px !important; */
/* } */
.MuiDataGrid-cell--textLeft[data-field="startDateTime"],
.MuiDataGrid-cell--textLeft[data-field="endDateTime"]{
    /* border: 1px solid green; */
    /* min-width:130px !important; NOTE: enable */
    /* padding-left:38px !important; NOTE:enable */
    /* margin-right: 12px; */
}

/* NOTE: enable */
/* .MuiDataGrid-cell--textLeft[data-field="hours"] {
    padding-left: 0px;
    min-width: 100px !important;
    max-width:100px !important;
} */

.MuiDataGrid-columnHeader--sortable[data-field="jobName"]{
    /* border:1px solid red; */
    /* min-width:100px !important; */
    /* width:100px !important; */
    /* padding-left: 32px !important; */
}

.MuiDataGrid-cell--textLeft[data-field="jobName"]{
    /* border: 1px solid green; */
    /* min-width:100px !important; */
    /* padding-left: 50px !important; NOTE: enlabe */
    /* padding-left: 32px !important; */
}

/* .MuiDataGrid-cell--textLeft[data-field="hours"]{
    text-align:center!important;
    justify-content: center !important;
} */

/* NOTE: day and view details */
/* each cell of data row */

.MuiDataGrid-columnSeparator--sideRight {
    display: none
}

.MuiDataGrid-cell--textLeft{
    justify-content:unset !important;
}
/* header row each cell */
/* .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader--sortable .MuiDataGrid-columnHeaderTitleContainer{
    justify-content: unset !important;
    padding-left: -10px !important;
} */

.MuiDataGrid-columnHeaderTitleContainer {
/* .css-cs172o-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{ */
    /* justify-content:center !important;NOTE: enable */
    /* border: 1px solid red; */
    /* margin-left: -10px !important; */


}

/* shift details  */
/* worker name data field */
.MuiDataGrid-columnHeader--sortable[data-field="workerName"]{
    /* border:1px solid green; */
    /* padding-left:52px !important;
    min-width: 260px !important;
    width:260px !important; */
}

.MuiDataGrid-cell--textLeft[data-field="workerName"]{
/* .css-cs172o-MuiDataGrid-root .MuiDataGrid-cell--textLeft[data-field="workerName"]{ */
    /* justify-content: flex-start !important;
    padding-left: 48px !important; */
    /* border: 1px solid red; */
    /* min-width: 260px !important;
    max-width:260px !important;
    text-overflow: ellipsis; */

}

.MuiDataGrid-columnHeader--sortable[data-field="viewed"],
.MuiDataGrid-columnHeader--sortable[data-field="notified"],
.MuiDataGrid-columnHeader--sortable[data-field="none"],
.MuiDataGrid-columnHeader--sortable[data-field="declined"]{
    /* border:1px solid green; */
    /* min-width:120px !important; */
    /* width:120px !important; */
    /* padding-left:42px !important; */
}
.MuiDataGrid-cell--textLeft[data-field="viewed"],
.MuiDataGrid-cell--textLeft[data-field="notified"],
.MuiDataGrid-cell--textLeft[data-field="none"],
.MuiDataGrid-cell--textLeft[data-field="declined"]{
    /* border:1px solid red; */
    /* text-align: center !important; */
    /* min-width:120px !important; */
    /* padding-left: 62px !important; */
}




/* .MuiDataGrid-columnHeader--sortable[data-field="confirmed"]{
    width:120px !important;
    padding-left:28px !important;
}
.MuiDataGrid-cell--textLeft[data-field="confirmed"]{
    padding-left: 60px !important;
} */
/* NOTE: shift details end */








/* eye button */
/* .MuiDataGrid-cell--textLeft[data-field="viewed"]{
    font-size: 24px;
} */

/* day report */

/* NOTE:  custom buttons*/


/* .MuiDataGrid-columnHeader[data-field="changeStatus"],
.MuiDataGrid-columnHeader[data-field="currentStatus"]{
    width: unset !important;
    max-width: 120px !important;
    min-width: unset !important;
}

.MuiDataGrid-columnHeader[data-field="currentStatus"]{
    min-width: 100px !important;
}

.MuiDataGrid-columnHeader[data-field="changeStatus"]{
    margin-left: 0px !important;
    padding-right: 20px !important;
    min-width: 140px !important;
}

.MuiDataGrid-cell--textLeft[data-field="changeStatus"]{
    width: unset !important;
    min-width: 140px !important;
}

.MuiDataGrid-cell--textLeft[data-field="currentStatus"]{
    padding-left: 30px !important;
} */


/* scrollbar */
.MuiDataGrid-virtualScroller::-webkit-scrollbar{
    border-radius: 100px;
    /* background:rgb(174, 174, 174); */
    width:6px;
    height:6px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb{
    background:rgb(149, 147, 147);
    height:6px !important;
    width:20px;
    border-radius: 100px;
}


.MuiDataGrid-cell--textLeft[data-field="currentStatusDaily"]{
    /* padding-left:42px; */
}



/* work center report */
